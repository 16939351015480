package com.steamstreet.vegasful.browser.account

import com.steamstreet.vegasful.browser.account.intelligence.Intelligence
import com.steamstreet.vegasful.browser.account.subscriptions.EntityInteractionsController
import com.steamstreet.vegasful.browser.account.subscriptions.myGuide
import com.steamstreet.vegasful.browser.appScope
import com.steamstreet.vegasful.browser.public.decodeURIComponent
import io.ktor.client.*
import io.ktor.client.engine.js.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.html.dom.append
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import kotlinx.serialization.json.long
import org.w3c.dom.*
import org.w3c.dom.url.URLSearchParams
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi

typealias Renderer = suspend (Element) -> Unit


val config: dynamic by lazy {
    window["vegasful"]
}

data class UserDetails(
    val id: String,
    val access_token: String,
    val refresh_token: String?,
    val expires: Instant
)

object Account {
    private var token: Token? = null
    private var user: UserDetails? = null
    private val jsonClient = HttpClient(JsClient())
    private val authJson = Json {
        ignoreUnknownKeys = true
    }

    val modules: Map<String, Renderer> = mapOf(
        "entity-interactions" to {
            coroutineScope {
                EntityInteractionsController(appScope, it).run()
            }
        },
        "my-guide" to ::myGuide,
        "login" to { element ->
            URLSearchParams(window.location.search).let {
                element.append {
                    loginDialog("Login to your Vegasful account", "Login")
                }
            }
        },
        "account-delete" to {
            println("Found account-delete")
            it.append {
                accountDelete(appScope)
            }
        },
        "intelligence" to {
            Intelligence(it, appScope).run()
        }
    )

    private fun executeModule(element: Element, module: String) {
        val moduleFunction = modules[module]
        if (moduleFunction != null) {
            appScope.launch {
                moduleFunction.invoke(element)
            }
        }
    }

    fun initializeAccountModules() {
        document.querySelectorAll("[data-account-module]").asList().forEach { node ->
            (node as? Element)?.let { element ->
                element.getAttribute("data-account-module")?.let {
                    executeModule(element, it)
                }
            }
        }
    }

    fun run() {
        appScope.launch {
            initAuth()
            initAccountMenu()
            initializeAccountModules()
        }
    }

    fun navigate(url: String) {
        window.location.href = url
    }

    @OptIn(ExperimentalEncodingApi::class)
    suspend fun initAuth() {
        token = document.cookie.split(";").map {
            it.trim()
        }.find { it.startsWith("vegasful-auth-token=") }
            ?.substringAfter("=")?.let {
                decodeURIComponent(it)
            }?.let {
                authJson.decodeFromString<Token>(it)
            }

        token?.access_token?.let { accessToken ->
            accessToken.split(".").getOrNull(1)?.let {
                val tokenData = Base64.withPadding(Base64.PaddingOption.ABSENT).decode(it)
                val userTokenString = tokenData.decodeToString()
                val userInfo = authJson.parseToJsonElement(userTokenString)

                user = UserDetails(
                    userInfo.jsonObject["sub"]!!.jsonPrimitive.content,
                    accessToken,
                    token?.refresh_token,
                    userInfo.jsonObject["exp"]!!.jsonPrimitive.long.let {
                        Instant.fromEpochSeconds(it)
                    }
                )

                console.dir(user!!)
            }
        }
    }

    private fun isTokenExpired(): Boolean {
        return (token?.expiration ?: Instant.DISTANT_PAST) < Clock.System.now()
    }

    /**
     * Get the token, refreshing as necessary.
     */
    private suspend fun getToken(): String? {
        return token?.access_token?.takeIf { !isTokenExpired() }
    }

    fun login(returnUrl: String? = null) {
        if (returnUrl != null) {
            localStorage.setItem(LOGIN_REDIRECT_KEY, returnUrl)
        }
        navigate("/login")
    }

    fun logout() {
        window.location.href = "${window.location.origin}/auth/logout"
    }

    fun isLoggedIn(): Boolean {
        return (token?.let {
            val tokenExpires = it.expiration ?: Instant.DISTANT_PAST
            tokenExpires > Clock.System.now() || it.refresh_token != null
        } ?: false)
    }

    /**
     * Load data from the given URL.
     */
    suspend fun load(url: String, requiresLogin: Boolean = false): String? {
        val token = getToken()
        if (requiresLogin && token == null) {
            return null
        }

        return try {
            jsonClient.get(url) {
                headers {
                    token?.let {
                        append("Authorization", "Bearer $it")
                    }
                }
            }.bodyAsText()
        } catch (t: Throwable) {
            t.printStackTrace()
            null
        }
    }
}

fun onReady(cb: suspend () -> Unit) {
    if (document.readyState === DocumentReadyState.COMPLETE || document.readyState === DocumentReadyState.INTERACTIVE) {
        appScope.launch {
            cb()
        }
    } else {
        document.addEventListener("DOMContentLoaded", {
            appScope.launch {
                cb()
            }
        })
    }
}


private const val LOGIN_REDIRECT_KEY = "vegasful_login_redirect"

@Serializable
public data class Token(
    val access_token: String,
    val refresh_token: String? = null,
    val id_token: String,
    val token_type: String,
    val expires_in: Int,
    var expiration: Instant? = null
)