package com.steamstreet.vegasful.browser.account

import com.steamstreet.vegasful.css.css
import kotlinx.browser.window
import kotlinx.css.*
import kotlinx.html.*
import kotlinx.html.js.onClickFunction

fun FlowContent.loginPanel() {
    fun FlowContent.loginButton(name: String, color: Color, provider: String) {
        a(href = "#") {
            css {
                width = 100.pct
                display = Display.flex
                this.color = Color.white
                backgroundColor = color
                fontSize = 18.px
                fontWeight = FontWeight.w600
                padding(8.px)
                borderRadius = 5.px
                marginTop = 8.px

            }
            onClickFunction = {
                window.location.href = "${window.location.origin}/auth/${provider.lowercase()}/signin"
            }
            img(src = "/assets/ui/identity/${provider}.png") {
                css {
                    width = 40.px
                    verticalAlign = VerticalAlign.middle
                    marginRight = 8.px
                }
            }
            span {
                css {
                    flexGrow = 1.0
                    textAlign = TextAlign.center
                    paddingTop = 9.px
                }
                +name
            }
        }
    }

    loginButton("Continue with Facebook", Color("#4267B2"), "Facebook")
    loginButton("Continue with Google", rgb(220, 38, 38), "Google")
    loginButton("Continue with Apple", rgb(10, 10, 10), "Apple")
}

/**
 * Render and manage the login dialog.
 */
fun <T> TagConsumer<T>.loginDialog(message: String, title: String) {
    accountDialog(title, {
        +message
    }, {
        loginPanel()
    })
}

